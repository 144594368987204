var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',[_c('template',{slot:"top-of-table"},[_c('v-text-field',{staticClass:"col col-lg-4 col-md-4 col-sm-4",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Cari","hide-details":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3 font-weight-bold",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showForm(true)}}},[_vm._v(" Tambah Data ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"slot":"data-table","headers":_vm.headers,"items":_vm.items,"footer-props":_vm.$helper.tableFooterConfig,"items-per-page":10,"server-items-length":_vm.dataCount,"options":_vm.options,"loading":_vm.isPageLoading,"page":_vm.currentPage,"fixed-header":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event}},slot:"data-table",scopedSlots:_vm._u([{key:"item.sub_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.$helper.currencyFormat(item.sub_total)))])]}},{key:"item.delivery_cost",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.$helper.currencyFormat(item.delivery_cost)))])]}},{key:"item.grand_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.$helper.currencyFormat(item.grand_total)))])]}},{key:"item.Action",fn:function(ref){
var item = ref.item;
return [_c('action-buttons',{on:{"edit":function($event){return _vm.showForm(false, item)},"delete":function($event){return _vm.deleteData(item)}}})]}}],null,true)}),_c('form-page',{attrs:{"slot":"form","show":_vm.show,"title":_vm.formTitle,"data":_vm.selectedData,"is-create":_vm.isCreate,"resellers":_vm.resellers,"products":_vm.products},on:{"close":function($event){return _vm.onClose()},"onSuccess":function($event){return _vm.getData()}},slot:"form"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }