<template>
  <modal
    :title="title"
    :show="show"
    @close="$emit('close')"
    :is-loading="loading"
    :fullscreen="true"
  >
    <v-form
      ref="form"
      lazy-validation
      class="d-flex flex-column"
      @submit.prevent="store"
    >
      <v-container class="pa-7 pt-3 elevation-1">
        <v-row>
          <v-col cols="6" class="pr-3">
            <v-row>
              <v-col cols="8">
                <div class="form-group">
                  <label class="text-caption font-weight-bold">
                    Reseller
                  </label>
                  <v-autocomplete
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Reseller', 'required')"
                    v-model="form.transaction.user_id"
                    placeholder="Pilih salah satu"
                    :items="resellers"
                    item-text="name"
                    item-value="id"
                    @change="resellerSelected"
                  />
                </div>
              </v-col>
              <v-col cols="4">
                <div class="form-group">
                  <label class="text-caption font-weight-bold">
                    Nomor Telepon
                  </label>
                  <v-text-field
                    single-line
                    dense
                    hide-details="auto"
                    v-model="form.transaction.user_phone"
                    disabled
                  />
                </div>
              </v-col>
            </v-row>
            <div class="form-group">
              <label class="text-caption font-weight-bold">
                Alamat Pengiriman
              </label>
              <v-autocomplete
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Alamat Pengiriman', 'required')"
                v-model="selectedAddress"
                placeholder="Pilih salah satu"
                :items="addresses"
                item-text="fullAddress"
                item-value="id"
                :return-object="true"
                @change="addressSelected"
              />
              <!-- <v-autocomplete
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Alamat Pengiriman', 'required')"
                v-model="selectedAddress"
                placeholder="Pilih salah satu"
                :items="selectedReseller.addresses"
                item-text="fullAddress"
                item-value="id"
                :return-object="true"
              /> -->
            </div>
          </v-col>
          <v-col cols="6" class="pl-3">
            <div class="form-group">
              <label class="text-caption font-weight-bold">
                Catatan Pengiriman
              </label>
              <v-textarea
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Catatan Pengiriman', 'required')"
                v-model="form.description"
                placeholder="Ketik disini"
                rows="3"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-7 pt-3 mt-3 elevation-1">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2 font-weight-bold mt-4">
              Detail Pembelian
            </div>
          </v-col>
          <v-col cols="6">
            <v-simple-table class="mt-0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="*">Nama Produk</th>
                    <th class="text-left" width="128">Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in details.filter(
                      (_, idx, arr) => idx < arr.length / 2
                    )"
                    :key="`product-${idx}`"
                  >
                    <td>{{ item.name }}</td>
                    <td>
                      <v-text-field
                        single-line
                        dense
                        hide-details="auto"
                        :rules="$rules('Qty', 'numeric')"
                        v-model.number="item.qty"
                        placeholder="0"
                        type="number"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="6">
            <v-simple-table class="mt-0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="*">Nama Produk</th>
                    <th class="text-left" width="128">Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in details.filter(
                      (_, idx, arr) => idx >= arr.length / 2
                    )"
                    :key="`product-2-${idx}`"
                  >
                    <td>{{ item.name }}</td>
                    <td>
                      <v-text-field
                        single-line
                        dense
                        hide-details="auto"
                        :rules="$rules('Qty', 'numeric')"
                        v-model.number="item.qty"
                        placeholder="0"
                        type="number"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <template slot="action">
      <v-btn color="secondary" class="mr-3" @click="$emit('close')" outlined>
        Batal
      </v-btn>
      <v-btn color="secondary" @click="store"> Simpan </v-btn>
    </template>
  </modal>
</template>

<script>
const EMPTY_OBJ = {
  transaction: {
    id: undefined,
    user_id: undefined,
    ref_id: undefined,
    trx_type: "RESELLER",
    payment_status: undefined,
    sub_total: null,
    delivery_cost: null,
    grand_total: null,
    city_id: null,
    address: "",
    record_status: "",
    user_phone: undefined
  },
  details: [
    {
      product_id: undefined,
      qty: null
    }
  ]
};
export default {
  name: "ProductForm",
  components: {
    Modal: () =>
      import(/* webpackChunkName: 'modal' */ "@/components/atom/Modal")
  },
  props: {
    isCreate: {
      default: true
    },
    show: {
      default: false
    },
    title: {
      default: ""
    },
    data: {
      default: function () {
        return {};
      }
    },
    resellers: {
      default: function () {
        return [];
      }
    },
    products: {
      default: function () {
        return [];
      }
    }
  },
  data: () => ({
    loading: false,
    form: { ...EMPTY_OBJ },
    selectedReseller: {},
    selectedAddress: {},
    details: [],
    addresses: []
  }),
  watch: {
    data: function (val) {
      this.form.transaction.id = val.id;
    },
    show: function (val) {
      if (val)
        this.$nextTick(async () => {
          await this.init();
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });
    }
  },
  methods: {
    init: async function () {
      if (this.isCreate) {
        this.form = { ...EMPTY_OBJ };
        this.selectedReseller = {};
        this.selectedAddress = {};
        this.details = [...this.products];
        this.addresses = [];
        return;
      }
      this.form = {
        transaction: {
          ...this.data,
          user_phone: this.data.user.phone,
          details: undefined,
          user: undefined
        },
        details: [...this.data.details]
      };
      let details = [];
      const existing = this.data.details;
      this.products.forEach((row) => {
        let obj = existing.find((x) => x.product_id === row.id);
        if (obj) {
          details.push({
            ...row,
            trid: obj.id,
            qty: obj.qty
          });
        } else {
          details.push({ ...row });
        }
      });
      this.details = details;
    },
    resellerSelected: async function (e) {
      const obj = this.resellers.find((x) => x.id === e);
      if (obj) {
        this.form.transaction.user_phone = obj.phone;
      }
      try {
        let response = await this.$http.get(`/users/${e}/shipment-addresses`);
        let {
          data: { success, data }
        } = response;
        if (success) {
          this.addresses = [
            ...data.data.map((x) => ({
              ...x,
              fullAddress: `${x.city}, ${x.address}`
            }))
          ];
        } else {
          this.addresses = [];
        }
      } catch {
        this.addresses = [];
      } finally {
        this.loading = false;
      }
    },
    addressSelected: async function (e) {
      this.form.transaction.city_id = e.city_id;
      this.form.transaction.address = e.address;
      this.form.transaction.shipment_address_id = e.id;
    },
    store: async function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url = `/transactions/${this.form.id}`;
        let method = "put";
        if (this.isCreate) {
          url = "/transactions";
          method = "post";
        }
        try {
          let {
            data: { success, message }
          } = await this.$http[method](url, {
            ...this.form
          });
          if (success) {
            this.$dialog.notify.info("Data berhasil disimpan", {
              position: "top-right",
              timeout: 3000
            });
            this.$emit("onSuccess");
            this.close();
          } else
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 3000
            });
        } catch (error) {
          // console.log("err", error);
        } finally {
          this.loading = false;
        }
      }
    },
    close: function () {
      this.$emit("close");
    }
  }
};
</script>
